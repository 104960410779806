
import { useState } from "react";
import {
    Box,
    Text,
    Tr,
    Td,
    Heading,
    Flex,
    Spacer,
    useColorModeValue,
} from '@chakra-ui/react';
import { FaCloudDownloadAlt, FaDownload, FaPrint, FaRegEye, FaRegFilePdf } from "react-icons/fa";
import ActionButtons from "./ActionButton";

function VoucherTicketList(props) {
    const { tickets, viewPdf } = props;
    const ticketTimeText = useColorModeValue('blackAlpha.800', 'whiteAlpha.800');
    const [isActive, setIsActive] = useState({});
    const toggleActive = (idx) =>
        setIsActive((prev) => {
            let status = {};
            // console.log('toggleActive', idx)
            if (prev[idx]) status =  { ...prev, [idx]: false };
            status = { ...prev, [idx]: true };
            // console.log('toggleActive status', status)
            return status;
        });

    // const viewPdf = async (ticketPdf) => {
    //     const pdfUrl = await getTicketPdfUrl(ticketPdf)
    //     console.log("Apro " + pdfUrl);
    //     window.open(pdfUrl, "_blank");
    //     // setPdfFileUrl(pdfUrl);
    //     // onTicketOpen();
    // }
    // console.log(tickets);  // Verifica il contenuto dell'oggetto
    // console.log(Object.keys(tickets));  // Verifica le chiavi dell'oggetto

    let id = null;
    if (tickets && typeof tickets === 'object' && Object.keys(tickets).length > 0) {
        id = Object.keys(tickets)[0];  // Ottieni la prima chiave
        // const firstTicket = tickets[firstKey];  // Ottieni il valore associato alla prima chiave

        // console.log(firstKey, firstTicket);
    }
    return (
        <>
            {id &&
                    <Tr
                        // onClick={() => { toggleActive(id); viewPdf(Buffer.from(tickets[id]['pdf_filename']).toString('base64')) }} 
                        // onClick={() => { toggleActive(id); viewPdf(tickets[id]['pdf_filename']) }}
                        onClick={() => { toggleActive(id); viewPdf(tickets[id]['pdf_enc_filename']) }}
                        bg={isActive[id] && 'gray.100' }
                        key={'ticket-'+id}
                        _hover={{ bg: "gray.200" }}
                    >
                        <Td colSpan={'2'} lineHeight={{ base: '14px', md: '22px' }}>
                            <Flex>

                            <ActionButtons leftIcon={<FaPrint />} variant='black' onClick={() => { toggleActive(id); viewPdf(tickets[id]['pdf_enc_filename']) }} text={'Stampa biglietti voucher'} />
                                {/* <HStack spacing={'16px'}> */}
                                {/* <Box>
                                    <Heading fontSize={{ base: '16px', md: '22px' }}>
                                        Stampa biglietti voucher
                                    </Heading>
                                </Box>
                                <Spacer />
                                <Box margin={'auto'}>
                                    <FaPrint size={'2em'}/>
                                </Box> */}
                                {/* </HStack> */}
                            </Flex>
                        </Td>
                    </Tr>
            }
        </>
    );

}

export default VoucherTicketList;
