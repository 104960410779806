
import React from "react";
// import { useSelector } from "react-redux";
import {
    Box,
    VStack,
    Input,
    Image,
    Text,
    Table,
    Thead,
    Tbody,
    Center,
    Tr,
    // Th,
    Link,
    Td,
    Button,
    TableContainer,
    useDisclosure,
    Modal,
    ModalBody,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    useColorModeValue,
} from '@chakra-ui/react';
import { useHistory } from "react-router-dom";
import { FaCamera, FaHome, FaTrashAlt } from "react-icons/fa";
import *  as CONFIG from '../config'
import { ButtonBox } from "./HomeButton";
import CapturePhoto from "./CapturePhoto";
// import { useSelector } from "react-redux";
// import { getApikey } from "../utils/SessionToken";
import { useState, useEffect } from "react";
import { deleteImage, storeVoucherNote } from "../utils/ApiUtilities";
import { getTicketPdfUrl, getVoucherImgUrl } from "../utils/AppUtilities";
import VoucherTicketList from "./VoucherTicketList";
// import VoucherItem from "./VoucherItem";
import VoucherFields from "./VoucherFields";
import ActionButtons from "./ActionButton";
import VoucherList from "./VoucherList";
// import { format } from 'date-fns'

function VoucherResults(props) {
    // const { whenList } = useSelector((state) => state.user);
    // console.log('whenList=' + format(whenList, 'dd/MM/yyyy'));
    const { isOpen: isDatamismatchOpen, onOpen: onDatamismatchOpen, onClose: onDatamismatchClose } = useDisclosure()
    const [pdfFileUrl, setPdfFileUrl] = useState(null);
    const history = useHistory();
    const { results, elaborated, updateResults, fromScan, resetScan, scanImage, setImage } = props;
    const [imgUrl, setImgUrl] = useState(undefined);
    const [note, setNote] = useState('')
    const noteBorderColor = useColorModeValue('gray.500', 'gray.400');
    // console.log('VoucherResults', results);
    // const descFontSize = 'xs';
    // const valueFontSize = 'xs';
    // const { nameUser, apiKey } = useSelector((state) => state.user);

    const viewPdf = async (ticketPdf) => {
        const pdfUrl = await getTicketPdfUrl(ticketPdf)
        // console.log("Apro " + pdfUrl);
        window.open(pdfUrl, "_blank");
        // setPdfFileUrl(pdfUrl);
        // onTicketOpen();
    }

    //Verificare il nultiplo ricaricamento dell'immagine
    const _imageUrl = React.useCallback(
        async () => {
            const url = await getVoucherImgUrl(results);
            // console.log("Recuper url immagine",);
            setImgUrl(url);
            // setImgUrl(await getVoucherImgUrl(results));
        },
        [setImgUrl, results]
    );

    useEffect(() => {
        _imageUrl();
        handleModal();
    }, [_imageUrl, results]);

    const handleModal = () => {
        console.log('results.noteDataMismatch=' + results.noteDataMismatch)
        results.noteDataMismatch && onDatamismatchOpen();
    }

    const handleDelete = async () => {
        // results.fileUploaded = false;
        // console.log('handleDelete', results.fileUploaded)
        // updateResults(results);
        await deleteImage(results.voucher.id)
        // handleResetScan()
        // restoreCapture();
        history.push(CONFIG.VOUCHER_CHECK_IN_FROM_LIST)
        // history.push(VOUCHER_ORPHAN_PHOTO);
    }

    // const handleCompleteCheckIn = async () => {
    //     localStorage.vouchercode = undefined;
    //     history.push(CONFIG.HOME_URL);
    //     // history.push(VOUCHER_ORPHAN_PHOTO);
    // }
    const handleStoreNote = async () => {
        // console.log('idImage', idImage)
        note !== '' && note !== undefined && await storeVoucherNote(note)
        localStorage.vouchercode = undefined;
        // restoreCapture();
        history.push(CONFIG.HOME_URL);
        // history.push(VOUCHER_ORPHAN_PHOTO);
    }

    const handleNote = (note) => {
        // console.log('note', note)
        setNote(note);
    }
    const handleRefreshClick = async () => {
        // console.log('handleRefreshClick');
        setTimeout(() => {
            window.location.reload(true);
            // window.location.href = window.location.protocol + '//' + window.location.host;
        }, 1000);
    }

    const handleResetScan = () => {
        // console.log('handleResetScanner')
        localStorage.vouchercode = undefined;
        resetScan(results);
        history.push(CONFIG.VOUCHER_SCAN)
    }

    return (
        <>
            <VStack>
                <Box mt={'10px'} mb={'100px'}>
                    <TableContainer
                        whiteSpace={'wrap'}
                    >
                        <Table
                            // variant='striped'
                            size={'sm'}
                        >
                            {/* <TableCaption>Voucher valido</TableCaption> */}
                            <Thead>
                                <Tr>
                                    <Td colSpan='2' textAlign={'center'} fontSize={{ base: '18px', md: '22px' }}>
                                        {results.success && !results.fileUploaded && fromScan ? <Text color={'blue.500'} lineHeight={'1.5em'} fontWeight={'bold'} fontSize={'32px'}>QR Code valido!</Text> : <></>}
                                        {results.success ? <Text color={'green'} lineHeight={'1.5em'} fontWeight={'bold'} >{results.message.length > 0 && results.message[0]}</Text> : <Text mb={'10px'} color={'red'} lineHeight={'1.5em'} fontWeight={'bold'}>{results.error.length > 0 && results.error[0]}</Text>}
                                        {/* {results.success && results.noteDataMismatch ? <Text color={'blue.500'} lineHeight={'1.5em'} fontWeight={'bold'} fontSize={'32px'}>{results.noteDataMismatch }</Text> : <></>} */}
                                        {results.success && !results.fileUploaded ?
                                            // <ButtonBox title={'Completa check-in'} icon={<FaCamera size={'1em'} />} fontSize={'1em'} link={VOUCHER_PHOTO} />
                                            <>
                                                <CapturePhoto updateResults={updateResults} setImage={setImage}></CapturePhoto>
                                                <ActionButtons onClick={() => { localStorage.vouchercode = undefined; history.push(CONFIG.HOME_URL) }} text={CONFIG.BUTTON_BACK_TO_LIST} />
                                                <ActionButtons onClick={handleResetScan} text={CONFIG.BUTTON_NEW_SCAN} variant={'orange'} />
                                            </>
                                            :
                                            <>
                                                {/* {!results.success && <ButtonBox title={CONFIG.BUTTON_ORPHAN} icon={<FaCamera size={'2em'} />} link={CONFIG.VOUCHER_ORPHAN_PHOTO} descText={CONFIG.NOTE_ORPHAN} />} */}
                                                {!results.success &&
                                                    <>
                                                        {/* <ButtonBox title={CONFIG.BUTTON_ORPHAN} icon={<FaCamera size={'1em'} />} fontSize={'1em'} link={CONFIG.VOUCHER_ORPHAN_PHOTO} descText={CONFIG.NOTE_ORPHAN} /> */}
                                                        {/* <ButtonBox title={CONFIG.BUTTON_ORPHAN} fontSize={'22px'} link={CONFIG.VOUCHER_ORPHAN_PHOTO} />
                                                    <Link onClick={handleResetScan}><ButtonBox title={'Nuova scansione'} fontSize={'22px'} link={''} forceReload={true} /></Link>
                                                    <ButtonBox title={'Lista voucher'} fontSize={'22px'} link={CONFIG.HOME_URL} paddingBottom={'15px'} /> */}
                                                        {/* <Button fontSize={'22px'} width={'100%'} onClick={() => { history.push(CONFIG.VOUCHER_ORPHAN_PHOTO) }} mr={'5px'}>{CONFIG.BUTTON_ORPHAN}</Button>
                                                    <Button fontSize={'22px'} width={'100%'} onClick={handleResetScan}>Nuova scansione</Button>
                                                    <Button fontSize={'22px'} width={'100%'} onClick={() => { history.push(CONFIG.HOME_URL) }} mr={'5px'}>{'Lista voucher'}</Button> */}

                                                        <ActionButtons onClick={() => { localStorage.vouchercode = undefined; history.push(CONFIG.VOUCHER_ORPHAN_PHOTO) }} text={CONFIG.BUTTON_ORPHAN} />
                                                        <ActionButtons onClick={handleResetScan} text={CONFIG.BUTTON_NEW_SCAN} variant={'orange'} />
                                                    <ActionButtons onClick={() => { localStorage.vouchercode = undefined; history.push(CONFIG.HOME_URL) }} text={CONFIG.BUTTON_BACK_TO_LIST} />
                                                        {/* <VoucherList /> */}
                                                        {/* <ButtonBox title={'Home'} icon={<FaHome size={'1em'} />} fontSize={'1em'} link={CONFIG.HOME_URL} paddingBottom={'15px'} /> */}
                                                    </>
                                                }
                                                {/* <ButtonBox title={'Home'} icon={<FaHome size={'1em'} />} fontSize={'1em'} link={CONFIG.HOME_URL} paddingBottom={'15px'} /> */}
                                            </>
                                        }
                                    </Td>
                                </Tr>
                            </Thead>
                            {!results.success ?
                                <Tbody>
                                    <Tr>
                                        <Td colSpan='2' textAlign={'center'} fontSize={'1.5em'}></Td>
                                    </Tr>
                                </Tbody>
                                :
                                <Tbody>
                                    {results.fileUploaded &&
                                        <Tr>
                                            <Td colSpan={'2'} textAlign={'center'} fontSize={'18px'} fontWeight={'bold'} lineHeight={'18px'}>
                                                <Center>
                                                    {scanImage && results.voucher.alreadyValidated !== true ?
                                                        <Image src={scanImage} /> :
                                                        imgUrl !== undefined && <Image src={imgUrl + '/' + Math.random()} maxHeight={'60%'} />
                                                    }
                                                    {/* <Image src={scanImage} />
                                                    {imgUrl !== undefined && <Image src={imgUrl + '/' + Math.random()} maxHeight={'60%'} /> } */}
                                                </Center>
                                                <Input
                                                    placeholder="Inserisci eventuali Note"
                                                    size='md'
                                                    variant="outline"
                                                    value={note}
                                                    onChange={(event) => handleNote(event.target.value)}
                                                    // mb={'12px'}
                                                    // width={'90%'}
                                                    borderColor={noteBorderColor}
                                                    mt={'8px'}
                                                />
                                                <Box mt={'-0px'} mb={'0px'}>
                                                    <ActionButtons fontSize={'16px'} leftIcon={<FaHome />} variant='green' width={'48%'} onClick={handleStoreNote} mr={'5px'} text={note !== '' ? CONFIG.BUTTON_SAVE_NOTE : CONFIG.BUTTON_BACK_TO_HOME} />
                                                    <ActionButtons fontSize={'16px'} leftIcon={<FaTrashAlt />} variant='red' width={'50%'} onClick={handleDelete} text={CONFIG.BUTTON_DELETE_CHECKIN} />
                                                    <ActionButtons leftIcon={<FaCamera />} width={'100%'} onClick={handleResetScan} text={CONFIG.BUTTON_NEW_SCAN} variant={'orange'} />
                                                    {/* <ActionButtons onClick={() => { history.push(CONFIG.HOME_URL) }} text={CONFIG.BUTTON_BACK_TO_LIST} /> */}
                                                </Box>
                                            </Td>
                                        </Tr>
                                    }
                                    {/* <Tr>
                                        <Td colSpan={'2'} textAlign={'center'} fontSize={'18px'} fontWeight={'bold'} lineHeight={'18px'}>{elaborated && results.voucher.description}</Td>
                                    </Tr> */}
                                    {results.fileUploaded &&
                                    <VoucherTicketList tickets={results.voucher.tickets} viewPdf={viewPdf} />
                                    }
                                    <VoucherFields results={results} elaborated={elaborated} />
                                    {/* {results.fileUploaded && */}
                                    {/* } */}
                                </Tbody>
                            }
                        </Table>
                    </TableContainer>
                </Box>
            </VStack>
            {/* { results.fileUploaded && */}
            <Modal
                // isCentered
                // onClose={onTicketClose}
                // isOpen={isTicketOpen}
                onClose={onDatamismatchClose}
                isOpen={isDatamismatchOpen}
                motionPreset='slideInBottom'
                scrollBehavior={'inside'}
                size={'full'}
                allowPinchZoom={true}
            >
                <ModalOverlay
                    bg='blackAlpha.300'
                    backdropFilter='blur(5px) '
                />
                <ModalContent height={'100%'}>
                    {/* <ModalHeader>Modal Title</ModalHeader> */}
                    {/* <ModalCloseButton size='sm' border={'none'} background={'hidden'} width={'10px'} padding={'20px'} /> */}
                    <ModalBody>
                        <VStack>
                            <Box mt={'60px'} mb={'100px'}>
                                <TableContainer
                                    whiteSpace={'wrap'}
                                >
                                    <Table
                                        // variant='striped'
                                        size={'sm'}
                                    >
                                        <Tbody>
                                            <Tr>
                                                <Td>
                                                    <Box mt={'-0px'} mb={'0px'}>
                                                        {/* <Text mb={'20px'} align='center' color={'blue.500'} lineHeight={'1.5em'} fontWeight={'bold'} fontSize={'32px'}>{results.noteDataMismatch}</Text> */}
                                                        <Text dangerouslySetInnerHTML={{ __html: results.noteDataMismatch }} mb={'32px'} align='center' color={'black.500'} lineHeight={'1.2em'} fontWeight={'bold'} fontSize={'28px'}></Text>
                                                        
                                                        <ActionButtons fontSize={'16px'} leftIcon='' variant='gray' width={'100%'} onClick={onDatamismatchClose} mr={'0px'} text='OK' mb={'10px'} mt={'10px'} />
                                                        <ActionButtons fontSize={'20px'} leftIcon={<FaTrashAlt />} variant='red' width={'100%'} onClick={handleDelete} text={CONFIG.BUTTON_DELETE_CHECKIN} />
                                                    </Box>
                                                </Td>
                                            </Tr>
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            </Box >
                        </VStack >
                        {/* <embed src="sample.pdf" frameborder="0" width="100%" height="400px"> */}
                    </ModalBody>

                    {/* <ModalFooter>
                            <Button colorScheme='gray' mr={3} onClick={handleCloseModal}>
                                Torna alla lista voucher
                            </Button> */}
                    {/* <Button variant='ghost'>Secondary Action</Button> */}
                    {/* </ModalFooter> */}
                </ModalContent>
            </Modal>
            {/* } */}

            {/* <Box maxW="7xl" mx={'auto'} pt={0} px={{ base: 2, sm: 12, md: 17 }} mb={'100px'}>
                {results.success && !results.fileUploaded ?
                    <ButtonBox title={'Completa check-in'} icon={<FaCamera size={'2em'} />} link={VOUCHER_PHOTO} />
                        :
                        <>
                        <ButtonBox title={'Check-in con foto'} icon={<FaCamera size={'2em'} />} link={VOUCHER_ORPHAN_PHOTO} />
                        <ButtonBox title={'Home'} icon={<FaHome size={'2em'} />} link={'/'} />
                        </>
                    }
                </Box> */}
        </>
    );

}

export default VoucherResults;
