
import { useState, useEffect } from "react";
import {
    Box,
    VStack,
    Center,
    Image,
    Text,
    Table,
    Button,
    Tbody,
    // Tfoot,
    Tr,
    // Th,
    Td,
    // TableCaption,
    TableContainer,
} from '@chakra-ui/react';
import { useHistory } from "react-router-dom";
import { FaCamera, FaTrashAlt } from "react-icons/fa";
import * as CONFIG from '../config'
import { ButtonBox } from "./HomeButton";
// import { getVoucherDetail } from "../utils/ApiUtilities";
import { getOrSetVouchercode } from "../utils/SessionToken";
import Loading from "../utils/Loading";
// import CapturePhoto from "./CapturePhoto";
// import Html5QrcodeReader from "./Html5QrcodeReader";
import { deleteImage, deleteOrphanImage } from "../utils/ApiUtilities";
import { getVoucherImgUrl } from "../utils/AppUtilities";
import VoucherTicketList from "./VoucherTicketList";
import VoucherFields from "./VoucherFields";
import ActionButtons from "./ActionButton";

function VoucherResultsApi(props) {
    // console.log('VoucherResultsApi');
    const { result, viewPdf } = props;
    const history = useHistory();
    // const [scanResult, setScanResult] = useState('');
    // const [aquired, setAquired] = useState(false);
    const [elaborated, setElaborated] = useState(false);
    const [results, setResults] = useState(false);
    const [loading, setLoading] = useState(true);
    const [tickets, setTickets] = useState(false);
    // const descFontSize = 'xs';
    // const valueFontSize = 'xs';
    // const { apiKey } = getApikey()
    const [imgUrl, setImgUrl] = useState(undefined);

    const handleDecode = async () => {
        setLoading(true);
        const { vouchercode } = await getOrSetVouchercode();
        // setAquired(true);
        // await getOrSetVouchercode(decodedText);
        // console.log('voucherCode', vouchercode)
        // const result = await getVoucherDetail(vouchercode);
        setImgUrl(await getVoucherImgUrl(result));
        // console.log('result', result);
        setResults(result);
        setTickets(result.voucher.tickets);
;        // setResults(await getVoucherDetail(vouchercode));
        setElaborated(true);
        setLoading(false);
    }

    useEffect(() => {
        handleDecode();
    }, []);

    const handleDelete = async () => {
        // results.fileUploaded = false;
        // console.log('handleDelete', results)
        // updateResults(results);
        // results.voucher.orphan ? console.log('orphan') : console.log('voucher')
        results.voucher.orphan ? await deleteOrphanImage(results.voucher.id) : await deleteImage(results.voucher.id)
        
        // restoreCapture();
        results.voucher.orphan ? history.push(CONFIG.VOUCHER_ORPHAN_PHOTO) : history.push(CONFIG.VOUCHER_CHECK_IN_FROM_LIST)
        // history.push(VOUCHER_ORPHAN_PHOTO);
    }

    // const viewPdf = async (ticketPdf) => {
    //     const pdfUrl = await getTicketPdfUrl(ticketPdf)
    //     console.log("Apro "+pdfUrl);
    //     window.open(pdfUrl, "_blank");
    // }

    // const _imageUrl = async () => {
    //     const { apiKey } = await getApikey();
    //     console.log('results', results);
    //     if (typeof results.voucher.id === 'undefined' || typeof results.voucher.image === 'undefined') {
    //         setImgUrl(undefined);
    //     }
    //     else {
    //         // setImgUrl(CONFIG.API_URL + CONFIG.VOUCHER_GETIMAGE_ENDPOINT + '/' + apiKey + '/' + results.voucher.id)
    //         results.voucher.image !== '' ? setImgUrl(CONFIG.API_URL + CONFIG.VOUCHER_GETIMAGE_ENDPOINT + '/' + apiKey + '/' + results.voucher.id) : setImgUrl(undefined);
    //     }
    //     // results.voucher ? setImgUrl(CONFIG.API_URL + CONFIG.VOUCHER_GETIMAGE_ENDPOINT + '/' + apiKey + '/' + results.voucher.id) : setImgUrl(undefined);
    // }
    // _imageUrl();
    return !loading ? (
            <>
                <VStack width={'100%'}>
                    <Box mt={'35px'} width={'100%'}>
                        {/* <Text>vouchercode: {elaborated && results.vouchercode}</Text>
            <Text>valid: {elaborated && results.voucher.valid}</Text>
            <Text>checked: {elaborated && results.voucher.checked}</Text>
            <Text>startdate: {elaborated && results.voucher.startdate}</Text>
            <Text>enddate: {elaborated && results.voucher.enddate}</Text>
            <Text>description: {elaborated && results.voucher.description}</Text>
            <Text>client: {elaborated && results.voucher.client}</Text>
            <Text>pax: {elaborated && results.voucher.pax}</Text>
            <Text>agency: {elaborated && results.voucher.agency}</Text>
            <Text>touroperator: {elaborated && results.voucher.touroperator}</Text>
            <Text>providergroup: {elaborated && results.voucher.providergroup}</Text> */}
                        {/* <Text>{scanResult}</Text> */}
                        <TableContainer
                            whiteSpace={'wrap'}
                        >
                            <Table
                                // variant='striped'
                                size='sm'
                            >
                                {/* <TableCaption>Voucher valido</TableCaption> */}
                                {/* <Thead>
                                    <Tr>
                                    <Th colSpan='2' textAlign={'center'} fontSize={'1.5em'} rounded={'25px'} mb={'10px'} color={'green.500'} lineHeight={'1.5em'}>
                                        {results.success ? <Text>{results.message.length > 0 && results.message[0]}</Text> : <></>}
                                        </Th>
                                    </Tr>
                                </Thead> */}
                            {!results.success ?
                                <Tbody>
                                    <Tr>
                                        <Td colSpan='2' textAlign={'center'} fontSize={{ base: '14px', md: '20px' }}><Text mb={'10px'} color={'red'} lineHeight={'1.5em'}>{results.error.length > 0 && results.error[0]}</Text></Td>
                                    </Tr>
                                </Tbody>
                                    :
                                <Tbody>
                                    <Tr>
                                        <Td colSpan={'2'} textAlign={'center'} fontSize={{ base: '16px', md: '22px' }} fontWeight={'bold'} lineHeight={'22px'}>{elaborated && results.voucher.description}</Td>
                                    </Tr>
                                    {imgUrl !== undefined &&
                                        <Tr>
                                            <Td colSpan={'2'} textAlign={'center'} fontSize={'18px'} fontWeight={'bold'} lineHeight={'18px'}>
                                                <Text fontSize={{ base: '13px', md: '16px' }} mb={'4px'}>{results.voucher.fastcheckin ? 'Fast Check-in' : 'Foto Check-in'} {results.voucher.checkdate}</Text>
                                                <Center>
                                                    {imgUrl !== undefined && <Image src={imgUrl + '/' + Math.random()} maxWidth={'100%'} />}
                                                </Center>
                                                <Box mt={'-0px'} mb={'0px'}>
                                                    {/* <Button leftIcon={<FaHome />} variant='green' width={'48%'} onClick={() => { handleCompleteCheckIn() }} mr={'5px'}>
                                                        Home
                                                    </Button> */}
                                                    <ActionButtons leftIcon={<FaTrashAlt />} variant='red' onClick={() => { handleDelete() }} text={CONFIG.BUTTON_DELETE_CHECKIN} />
                                                    <Text fontSize={{ base: '13px', md: '16px' }} mt={'10px'}>Cliccando su "Annulla check-in" sarà possibile rieffettuare il check-in e sostituire la foto.</Text>
                                                </Box>
                                            </Td>
                                        </Tr>
                                    }
                                    {imgUrl !== undefined &&
                                        <VoucherTicketList tickets={tickets} viewPdf={viewPdf} />
                                    }
                                    <VoucherFields results={results} elaborated={elaborated} />
                                    {/* {results.voucher.valid && */}
                                    {/* } */}
                                    </Tbody>
                                }
                            </Table>
                        </TableContainer>
                    </Box>
                </VStack>

            <Box maxW="7xl" mx={'auto'} pt={0} px={{ base: 2, sm: 12, md: 17 }} mb={'10px'}>
                {/* <SimpleGrid columns={{ base: 1, md: 2 }} spacing={{ base: 5, lg: 8 }}> */}
                {/* <ButtonBox title={'Scansiona un Voucher'} icon={<FaQrcode size={'2em'} />} state={state} link={VOUCHER_SCAN} /> */}
                {results.success && results.voucher.valid && !results.voucher.checked ?
                    <ButtonBox title={'Completa check-in'} icon={<FaCamera size={'2em'} />} link={CONFIG.VOUCHER_CHECK_IN_FROM_LIST} />
                    // <Html5QrcodeReader _results={results} _elaborated={true} _aquired={true} ></Html5QrcodeReader>
                    // <CapturePhoto></CapturePhoto>
                    :
                    <></>
                    // <>
                    // <ButtonBox title={'Riprova'} icon={<FaQrcode size={'2em'} />} link={VOUCHER_SCAN} />
                    // <ButtonBox title={'Home'} icon={''} link={'/'} />
                    // </>
                }
                {/* </SimpleGrid> */}
            </Box>
            </>
    ) : (
            <Loading label='Loading...' size='120px' />
    );

}

export default VoucherResultsApi;
