import { Tr, Td } from "@chakra-ui/react";
// import VoucherTicketList from "./VoucherTicketList";

const descFontSize = { base: '12px', md: '16px' };
const valueFontSize = { base: '14px', md: '18px' };
const lineHeight = { base: '22px', md: '26px' };

const VoucherFields = ({ results, elaborated }) => (
    <>
        {/* <VoucherItem title={'Cliente'} value={elaborated && results.voucher.client} />
        <VoucherItem title={'Num persone'} value={elaborated && results.voucher.pax} />
        <VoucherItem title={'Valido dal'} value={elaborated && results.voucher.startdate} />
        { results.voucher.startdate !== results.voucher.enddate &&
        <VoucherItem title={'Valido al'} value={elaborated && results.voucher.enddate} />
        }
        <VoucherItem title={'Agenzia'} value={elaborated && results.voucher.agency} />
        <VoucherItem title={'Tour operator'} value={elaborated && results.voucher.touroperator} />
        <VoucherItem title={'Note'} value={elaborated && results.voucher.note} /> */}

        <Tr>
            <Td colSpan={'2'} textAlign={'center'} fontSize={'16px'} fontWeight={'bold'} lineHeight={'18px'}>{elaborated && results.voucher.programservice}</Td>
        </Tr>
        <Tr>
            <Td fontSize={descFontSize} lineHeight={lineHeight}>Voucher</Td>
            <Td fontSize={valueFontSize}>{elaborated && results.voucher.vouchercode}</Td>
        </Tr>
        <Tr>
            <Td fontSize={descFontSize} lineHeight={lineHeight}>Cliente</Td>
            <Td fontSize={valueFontSize}>{elaborated && results.voucher.client}</Td>
        </Tr>
        <Tr>
            <Td fontSize={descFontSize} lineHeight={lineHeight}>Num persone</Td>
            <Td fontSize={valueFontSize}>{elaborated && results.voucher.paxExpand}</Td>
        </Tr>
        {results.voucher.numcarrier > 0 &&
            <Tr>
                <Td fontSize={descFontSize} lineHeight={lineHeight}>Num {results.voucher.carriername}</Td>
                <Td fontSize={valueFontSize}>{elaborated && results.voucher.numcarrier}</Td>
            </Tr>
        }
        <Tr>
            <Td fontSize={descFontSize} lineHeight={lineHeight}>Valido dal</Td>
            <Td fontSize={valueFontSize}>{elaborated && results.voucher.startdate}</Td>
        </Tr>
        {results.voucher.startdate !== results.voucher.enddate &&
            <Tr>
                <Td fontSize={descFontSize} lineHeight={lineHeight}>Valido al</Td>
                <Td fontSize={valueFontSize}>{elaborated && results.voucher.enddate}</Td>
            </Tr>
        }
        {/* <Tr>
            <Td fontSize={descFontSize}>Descrizione</Td>
            <Td fontSize={valueFontSize}>{elaborated && results.voucher.description}</Td>
        </Tr> */}
        <Tr>
            <Td fontSize={descFontSize} lineHeight={lineHeight}>Agenzia</Td>
            <Td fontSize={valueFontSize}>{elaborated && results.voucher.agency}</Td>
        </Tr>
        <Tr>
            <Td fontSize={descFontSize} lineHeight={lineHeight}>Tour operator</Td>
            <Td fontSize={valueFontSize}>{elaborated && results.voucher.touroperator}</Td>
        </Tr>
        <Tr>
            {/* <Td colSpan={'2'} fontSize={descFontSize} lineHeight={lineHeight}>Note: {elaborated && results.voucher.note ? results.voucher.note : 'Nessuna nota'}</Td> */}
            <Td fontSize={descFontSize} lineHeight={lineHeight}>Note</Td>
            <Td fontSize={valueFontSize}>{elaborated && results.voucher.note ? results.voucher.note : 'Nessuna nota'}</Td>
        </Tr>
    </>
);

export default VoucherFields;