import React, { Component } from "react";
import { Badge } from "@chakra-ui/react"

class Clock extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dateClass: new Date()
        }

        this.time = this.state.dateClass.toLocaleTimeString();
        // this.hourMin = this.time.length === 10 ? this.time.slice(0) : this.time.slice(0, 5);
    }

    setTime = () => {
        this.setState({
            dateClass: new Date()
        })
        this.time = this.state.dateClass.toLocaleTimeString();
        // this.hourMin = this.time.length === 10 ? this.time.slice(0) : this.time.slice(0, 5);
        // console.log(this.hourMin)
    }

    componentDidMount() {
        setInterval(this.setTime, 1000)
    }

    render() {
        return (
            <Badge mr={'20px'} fontSize={'16px'} fontFamily={'mono'}>
                {this.time}
            </Badge>
        )
    }
}

export default Clock;